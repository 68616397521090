<template>
  <div class="flow-order">
    <div class="mine-title">{{$t('route.flow_order')}}
      <span>{{$t('mine.tips')}}</span>
      <span><router-link to="/download" class="colorBtn">{{$t('mine.app')}}</router-link></span>
    </div>
    <van-tabs v-model="currentStatus" color="#2564F6" title-active-color="#2564F6" title-inactive-color="#666666"
     animated swipeable :ellipsis="false" @change="onChangeTabHandler">
      <van-tab :title="item.title" :name="item.status" v-for="(item,index) in tabs" :key="index">
        <a-spin size="large" :spinning="loading">
          <div class="table-page-table-wrapper">
            <a-table 
              :columns="columns" 
              row-key="id" 
              size="middle"
              :pagination="pagination" 
              :data-source="orders"
              @change="handleTableChange"
              :locale="{emptyText: $t('mine.empty')}"
              :scroll="{ x: 900 }"
              >
              <template slot="iccid" slot-scope="text,record">
                <span style="user-select: all;">{{ record.sim_number }}</span>
              </template>
              <template slot="product" slot-scope="text,record">
                <a-space>
                  <div class="product-img">
                    <img :src="record.image||record.icon" alt="" srcset="">
                  </div>
                  <div class="two-line-ellipsis" :title="record.district_name + ' ' + record.spec">
                    {{ record.district_name }}
                    {{ record.spec }}
                  </div>
                </a-space>
              </template>
              <template slot="add_time" slot-scope="text,record">
                <span>
                  {{ record.add_time | timeFilter }}
                </span>
              </template>
              <template slot="pay_amount" slot-scope="text,record">
                <span>
                  {{ record.currency }}
                  {{ record.pay_amount }}
                </span>
              </template>
               <template slot="action" slot-scope="text,record">
                <div v-if="record.status == 0" 
                @click="onPayHandler(record)"
                style="color: #B50000; cursor: pointer;"
                >
                  {{$t("mine.order_8")}}
                </div>
              </template>
            </a-table>
          </div>
          <div class="order-xs-wrapper">
            <div style="margin: 20px 0;">
              <van-card
                v-for="(item,index) in orders" :key="index"
                :currency="item.currency"
                :num="item.add_time | timeFilter"
                :price="item.pay_amount"
                :desc="item.sim_number"
                :title="item.district_name + item.spec + item.days_text"
                :thumb="item.image||item.icon"
              >
                <template #tags>
                  <a-space>
                    <van-tag plain :type="item.status == 0 ? 'danger' : 'primary '">{{item.status_text}}</van-tag>
                    <van-tag plain type="primary" v-if="item._status.payType">{{item._status.payType}}</van-tag>
                  </a-space>
                </template>
                <template #footer>
                  <van-button size="mini" color="#B50000" v-if="item.status == 0" @click="onPayHandler(item)">
                    {{$t("mine.order_8")}}
                  </van-button>
                </template>
              </van-card>
            </div>
            <a-empty v-show="!orders.length" :image="emptyImage" :description="$t('mine.order_10')"/>
            <div class="flex" v-show="!loading">
              <a-pagination simple @change="handlePageChange" v-model="pagination.current" :defaultPageSize="pagination.pageSize" :total="pagination.total" />
            </div>
          </div>
        </a-spin>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
//充值状态：0，待支付 1，已支付 2，已取消
import moment from 'moment';
import { Empty } from 'ant-design-vue';
export default {
  name:'FlowOrder',
  data() {
    return {
      emptyImage:Empty.PRESENTED_IMAGE_SIMPLE,
      tabs:[
        {title:this.$t('mine.tab_1'),status:'all'},
        {title:this.$t('mine.tab_2'),status:0},
        {title:this.$t('mine.tab_7'),status:1},
        {title:this.$t('mine.tab_8'),status:2},
      ],
      currentStatus:'all',
      loading:false,
      orders:[],
      pagination:{
        current: 1,
        pageSize: 5,
        total:0
      },
    }
  },
  computed:{
    columns(){
      return [
        { title: this.$t("common.title_5"),dataIndex: 'sim_number', scopedSlots: { customRender: 'iccid' },width: 200},
        { title: this.$t("mine.order_1"),dataIndex: 'district_name', scopedSlots: { customRender: 'product' },width: 180 },
        { title: this.$t("mine.order_2"),dataIndex: 'add_time', scopedSlots: { customRender: 'add_time' } },
        { title: this.$t("mine.order_3"),dataIndex: 'pay_amount', scopedSlots: { customRender: 'pay_amount' } },
        { title: this.$t("mine.order_4"),dataIndex: 'status_text', },
        { title: this.$t("mine.order_5"),dataIndex: '_status.payType' },
        { title: this.$t("mine.order_6"),dataIndex: 'action', scopedSlots: { customRender: 'action' } },
      ]
    }
  },
  filters:{
    timeFilter(text){
      return moment.unix(text).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  mounted(){
    this.init()
  },
  methods:{
    init(){
      this.getOrders()
    },
    async getOrders(){
      this.loading = true
      const status = this.currentStatus
      const { pageSize } = this.pagination
      const { data } = await this.$http.post(this.APIURL.ORDER.FLOWLIST,{...(status!=='all'&&{status}),limit:pageSize,page:1},false)
      this.loading = false
      this.pagination.total = data.total
      this.orders = data.list
    },
    async handleTableChange(pagination){
      const status = this.currentStatus
      const { current, pageSize } = pagination
      this.pagination = pagination
      const { data } = await this.$http.post(this.APIURL.ORDER.FLOWLIST,{...(status!=='all'&&{status}),limit:pageSize,page:current})
      this.orders = data.list
    },
    async handlePageChange(current, pageSize){
      const status = this.currentStatus
      this.pagination.current = current
      const { data } = await this.$http.post(this.APIURL.ORDER.FLOWLIST,{...(status!=='all'&&{status}),limit:pageSize,page:current})
      this.orders = data.list
    },
    onChangeTabHandler(){
      this.pagination = {current: 1,pageSize: 5,total:0}
      this.getOrders()
    },
    onPayHandler(record){
      const { sim_number,district_name,currency,plan_id,image,icon,price,spec,discount_amount,order_no } = record
      this.$router.push({ 
      name: 'Pay', 
      query: {
        type:1,
        name:district_name,
        cover:image||icon,
        sim_number,
        order_no,
        currency,
        plan_id,
        price,
        spec,
        ...discount_amount&&{discount_amount}
      }});
    },
  },
}
</script>
<style lang="scss" scoped>
</style>